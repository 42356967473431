import React from 'react'
import App_1 from './Component/App_1'

export default function App() {
  return (
    <div>
      <App_1/>
    </div>
  )
}
