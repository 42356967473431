import React from 'react'
import '../Component/App.css'
import { RxHamburgerMenu } from "react-icons/rx";
import logo from '../Assests/logo.png'
import logo_2 from '../Assests/logo_2.png'

export default function App_1() {
  return (
   <div >
  
 
{/* mobile */}

<div>
      {/* sec_1 */}
      <div className='md:hidden  w-full flex  items-center min-h-[100vh] sec_2 text-white flex-col '>
      <div className='w-full '>
      <img src={logo_2} className='w-[7rem] ml-6 ' alt="" />
      </div>
             {/* <div className='flex  items-center justify-between w-full'>
                <div className='w-[5rem] h-[5rem] bg-white'></div>
             
             <div>
             <RxHamburgerMenu  size={25}/>
             </div>
                
            <div className='flex justify-center  items-center '>

                <h1 className='pb-2'>FB</h1>
                <h1 className='pb-2'>FB</h1>
                <h1 className='pb-2'>FB</h1>
            </div>
            </div>  */}


            <div className='mt-[2rem] flex justify-center items-start flex-col px-4 '>
                <div className='flex flex-col justify-center items-center  '>
                {/* Transforming Entertainment Experience */}
                <h1 className='uppercase text-[2rem] font-bold  '>Transforming  <span className='grad'>Entertainment</span> <br /> <span className=''>Experience_</span> </h1>
                <p>We're working on a revolutionary entertainment Journy. Don’t miss out on the future of storytelling and fun.</p>
                <div className='flex justify-center items-start w-full flex-col  space-y-4 mt-[3rem]'>
                    <button className='py-4 px-12 bg-gradient-to-r from-yellow-600 to-red-600'>Don’t Miss It</button>
                    <button className='hidden py-4 px-14 border-2 bg-transparent'>EXPLORE</button>
                </div>
                </div>
            </div>
        </div>
</div>

{/* desktop */}
<div className='hidden md:flex  '>
        {/* sec_1 */}
        <div className='w-[50%]  flex  justify-start  flex-col items-center min-h-[100vh] bg-[#040d25] text-white'>
            {/* <div className='flex  items-center justify-between h-[100vh]  flex-col border-r w-[8rem]'> */}
                {/* <div className='w-[5rem] h-[5rem] bg-white'></div>
             
             <div>
             <RxHamburgerMenu  size={25}/>
             </div>
                
            <div className='flex justify-center  items-center flex-col'>

                <h1 className='pb-2'>FB</h1>
                <h1 className='pb-2'>FB</h1>
                <h1 className='pb-2'>FB</h1>
            </div> */}
            {/* </div> */}


         <div className='w-[80%] '>
         <img src={logo_2} className='w-[7rem] ml-[-2rem]' alt="" />
         </div>
            <div className='flex-1 flex justify-center items-center flex-col '>
                <div className='w-[80%] space-y-9 flex flex-col items-start '>
                <h1 className='uppercase text-[2.5rem] lg:text-[3rem] font-semibold'>Transforming  <span className='grad'>Entertainment</span> <br /> <span className=''>Experience_</span></h1>
                <p>We're working on a revolutionary entertainment Journy. Don’t miss out on the future of storytelling and fun.</p>
                <div className='flex justify-center items-center space-x-6'>
                    <button className='py-4 px-12 bg-gradient-to-r from-yellow-600 to-red-600'>Don’t Miss It</button>
                  
                </div>
                </div>
            </div>
        </div>

        {/* sec_2 */}
        <div className=' w-[50%]'>
            <div className=' sec_2 min-h-[100vh]'>
                {/* <div className='flex justify-center items-center flex-col min-h-[100vh] text-white font-semibold'>
                    <h1 className='uppercase mb-4'>Well Be Back In</h1>
                     <div className='flex justify-center items-center space-x-11 text-[2rem] font-bold'>
                        1
                        <div className='flex justify-center items-center flex-col'>
                            <h1>127</h1>
                            <p>Days</p>
                        </div>
                        
                        2
                        <div className='flex justify-center items-center flex-col'>
                            <h1>127</h1>
                            <p>Days</p>
                        </div>
                        
                        3
                        <div className='flex justify-center items-center flex-col'>
                            <h1>127</h1>
                            <p>Days</p>
                        </div>
                        
                     </div>
                </div> */}
            </div>
        </div>
  
    </div>
   </div>
  )
}
